<template>
  <div class="msg_list">
    <slot-header :showIcon="false" :showBackIcon="false">
      <div>{{ $route.meta.name }}</div>
    </slot-header>
    <div class="toggle_buttons">
      <div class="edit_button" @click="isEditing = !isEditing">
        {{ isEditing ? "取消" : "编辑" }}
      </div>
      <div class="delete_button" v-show="isEditing" @click.self="deleteMessageHandler">
        删除
      </div>
    </div>
    <div class="msg_list_body">
      <div v-if="msgList != ''">
        <div v-for="item in msgList" :key="item.conversationID" class="msg_list_body_item">
          <div class="msg_list_body_item_radio" v-if="isEditing">
            <Checkbox :isEditing="isEditing" :value="item.conversationID" @pushDeleteMessage="pushDeleteMessageHandler" @removeDeleteMessage="removeDeleteMessageHandler"> </Checkbox>
          </div>
          <div class="msg_list_body_item_avatar" @click="goToChart(item)">
            <img :src="getAvatar(item.userProfile.avatar)" />
          </div>
          <div class="msg_list_body_item_info" @click="goToChart(item)">
            <div class="msg_list_body_item_info_row">
              <div class="msg_list_body_item_info_name">
                {{ item.userProfile.nick || item.userProfile.userID }}
              </div>
              <div class="msg_list_body_item_info_time">
                {{ getTimeFormat(item.lastMessage.lastTime) }}
              </div>
            </div>
            <div class="msg_list_body_item_info_row">
              <div class="msg_list_body_item_info_msg">
                {{ getItemQuickView(item) }}
              </div>
              <div v-if="item.unreadCount" class="msg_list_body_item_info_unread">
                {{ item.unreadCount }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="msgList">
        <NoData />
      </div>
    </div>
    <footer-menu />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import SlotHeader from "@/components/SlotHeader";
import Checkbox from "./Checkbox.vue";
import NoData from "@/components/NoData.vue";
import FooterMenu from "@/components/FooterMenu";
import moment from "moment";

export default {
  name: "MsgList",
  components: {
    SlotHeader,
    FooterMenu,
    Checkbox,
    NoData,
  },
  data() {
    return {
      isEditing: false,
      deleteMessages: [],
    };
  },
  computed: {
    ...mapState("message", ["msgList", "isTimLogin"]),
  },
  methods: {
    ...mapActions("message", ["getMsgList", "deleteConversation"]),
    getAvatar(avatar) {
      if (avatar) return avatar;
      return require("@/assets/images/public/ic_my_head.webp");
    },
    getTimeFormat(time) {
      let tempMoment = moment(time * 1000);
      if (tempMoment.startOf("day").valueOf() === +new Date().setHours(0, 0, 0, 0)) return moment(time * 1000).format("HH:mm");
      return moment(time * 1000).format("MM月DD日 HH:mm");
    },
    goToChart(item) {
      this.$router.push(`/message/${encodeURIComponent(item.userProfile.nick || item.userProfile.userID)}/${item.conversationID}/${item.userProfile.userID}`);
    },
    getItemQuickView(item) {
      switch (item.lastMessage.type) {
        case "TIMImageElem":
          return "[图片]";
        case "TIMAudioElem":
          return "[语音]";
        case "TIMVideoElem":
          return "[影片]";
        default:
          return item.lastMessage.payload.extension ? `[${item.lastMessage.payload.extension}]` : item.lastMessage.payload.text;
      }
    },
    removeDeleteMessageHandler(message) {
      this.deleteMessages = this.deleteMessages.filter((msg) => msg !== message);
    },
    pushDeleteMessageHandler(message) {
      this.deleteMessages.push(message);
    },
    deleteMessageHandler() {
      if (this.deleteMessages.length === 0) {
        this.$message({
          message: "未选取讯息 !",
          iconClass: "x",
          center: true,
          customClass: "error_message",
        });
      } else {
        this.deleteMessages.forEach((conversationID) => {
          this.deleteConversation(conversationID);
          this.removeDeleteMessageHandler(conversationID);
        });
      }
    },
  },
  created() {
    if (this.isTimLogin) this.getMsgList();
  },
  watch: {
    isEditing(value) {
      if (value === false) {
        this.deleteMessages = [];
      }
    },
    deleteMessages(value) {
      console.log("deleteMessages", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.msgList {
  height: 100%;
}

.msg_list {
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 480px) {
    height: 85vh;
  }
  &_body {
    flex: auto 1 1;
    overflow-y: auto;

    &_item {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem;

      &_avatar {
        img {
          width: 4rem;
          border-radius: 50%;
        }
      }

      &_radio {
        height: inherit;
        display: flex;
        width: 12%;
        align-self: center;
        padding: 1rem 0;
        margin-right: 2%;
      }

      &_info {
        flex: auto 1 1;
        margin-top: 0.5rem;
        margin-left: 0.5rem;
        border-bottom: 1px solid var(--light-gray);

        &_row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;
        }

        &_name {
          font-size: 1.3rem;
          font-weight: 600;
        }

        &_msg {
          // max-width: 60vw;
          max-width: 249px;
          width: 100%;
          color: var(--font-gray);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: left;
        }

        &_time {
          font-size: 0.8rem;
          color: var(--font-gray);
        }

        &_unread {
          background: var(--red);
          color: var(--white);
          border-radius: 1rem;
          padding: 0.25rem 0.5rem;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 398px) {
  .msg_list_body_item_avatar {
    img {
      width: 3.5rem;
    }
  }

  .msg_list_body_item_info_row {
    margin-bottom: 0.6rem;
  }
}

.toggle_buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  // max-width: 414px;
  margin: 0 auto;
  @media (min-width: 480px) {
    max-width: unset;
  }
  div {
    padding: 1rem;
    cursor: pointer;
  }

  .delete_button {
    color: red;
  }
}
</style>
