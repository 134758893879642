<template>
  <div class="checkbox">
    <input type="checkbox" :value="value" v-model="checked" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    checked(value) {
      this.changeCheckbox(value);
    },
  },
  data() {
    return {
      checked: false,
    };
  },
  computed: {},
  methods: {
    changeCheckbox(checked) {
      if (checked) {
        this.$emit("pushDeleteMessage", this.value);
      } else {
        this.$emit("removeDeleteMessage", this.value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* Basic styling */
.checkbox {
  [type="checkbox"] {
    width: 1.25rem;
    height: 1.25rem;
    color: #62bc99;
    vertical-align: middle;
    -webkit-appearance: none;
    background: none;
    border: 0;
    outline: 0;
    flex-grow: 0;
    border-radius: 50%;
    background-color: #ffffff;
    transition: background 300ms;
    cursor: pointer;
  }

  /* Pseudo element for check styling */

  [type="checkbox"]::before {
    content: "";
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 0;
    background-color: transparent;
    background-size: contain;
    box-shadow: inset 0 0 0 1px #ccd3d8;
  }

  /* Checked */

  [type="checkbox"]:checked {
    background-color: currentcolor;
  }

  [type="checkbox"]:checked::before {
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
  }

  /* Disabled */

  [type="checkbox"]:disabled {
    background-color: #ccd3d8;
    opacity: 0.84;
    cursor: not-allowed;
  }
}
</style>